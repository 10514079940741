/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState, Fragment, useLayoutEffect } from "react";
import { SiteLink, useRouter } from "../../components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { NavBar } from "./navBar";
import { BackButton } from "../backButton/backButton";
import { Hamburger } from "../hamburger/hamburger";
import style from "./topHeaderStyle";

export function TopHeader(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const { path } = useRouter();
  const [topStyle, setTopStyle] = useState("norm__page");

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //console.log(currPos.x)
      //console.log(currPos.y);
      if (currPos.y > 90) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
      //setScrolled(currPos.y > 90)
    },
    [],
    null,
    true
  );

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }
  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  useLayoutEffect(() => {
    setTopStyle("norm__page");
    if (path.includes("/itinerary/")) {
      setTopStyle("itinerary__page__top");
    }
  }, [path]);

  return (
    <Fragment>
      <header
        css={[style.top, scrolled && style.topScrolled]}
        id='top'
        className={`header-top-bar ${topStyle}`}
      >
        <BackButton />

        <SiteLink
          to='/'
          css={[style.logo, scrolled && style.logoScrolled]}
          onClick={backTop}
          className='site-link'
        >
          <img css={style.logoImg} src='/assets/logos/logo.png' alt='logo' />
        </SiteLink>

        <Hamburger
          opened={menuOpened}
          toggleMenu={toggleMenu}
          scrolled={scrolled}
        />
        <NavBar open={menuOpened} onCloseMenu={onCloseMenu}></NavBar>
        <div
          className='wave-animation-bottom in-header'
          style={{ color: "#f26c33" }}
        >
          <svg
            width='1440px'
            height='25px'
            viewBox='0 0 1440 25'
            preserveAspectRatio='none'
          >
            <g data-svg-origin='0 0'>
              <path
                fill='currentColor'
                d='M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5'
              ></path>
            </g>
          </svg>
        </div>
      </header>
    </Fragment>
  );
}
