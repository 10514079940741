/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react';
import utils from 'utils';
import { GalleryTiles } from './galleryTiles';

import { Portal } from "site/portal/portal";
import FsLightbox from 'fslightbox-react';

const r = utils.site.resourcePath
//const set = utils.media.getImageSetPath

export function Gallery({ images, useSlider = false }) {

  const [toggler, setToggler] = React.useState(false);
  const [popupIndex, setPopupIndex] = React.useState(0);

  const filtered = utils.array.distinct(images, (x, y) => x.imageUrl === y.imageUrl)
  if (filtered.length === 0) return null;

  const popupImages = filtered.map(x => r(x.imageUrl));
  //const galleryImages = filtered.map(x => ({ imageUrl: r(x.imageUrl), thumbnailUrl: r(set(x.imageUrl, 'tiles_quare')), caption: x.caption }));
  const galleryImages = filtered.map(x => ({ imageUrl: r(x.imageUrl), thumbnailUrl: r(x.imageUrl), caption: x.caption }));
  // console.log(popupImages)

  function openPopupImage(e, image) {
    e.stopPropagation(); e.preventDefault();
    const imageUrl = r(image.imageUrl);
    const inx = utils.array.findIndex(popupImages, x => x === imageUrl);
    setPopupIndex(inx >= 0 ? inx : 0)
    setToggler(!toggler)
  }

  return <React.Fragment>
    <Portal>
      <FsLightbox
        toggler={toggler}
        sources={popupImages}
        sourceIndex={popupIndex}
      />
    </Portal>
    <GalleryTiles images={galleryImages} openPopupImage={openPopupImage} useSlider={useSlider} />
  </React.Fragment>
}