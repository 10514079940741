import { mq, variables } from '../../../cssInJs'; //mqMax,

export default {

    atdw__popup__page__back__to__menu: mq({
        //marginBottom : '0.5rem',
        fontWeight: '400',
        display: ['block', null, 'none'],
        position: 'fixed',
        top: '5%',
        backgroundColor: 'white',
        zIndex: 9,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '0px',
        paddingTop: '0.7rem',
        width: '100%',
        //paddingTop : '10px',
        //paddingBottom: '5px',
        //marginTop : '-15px',
        borderBottom: '1px solid',
        'svg': {
            width: '20px',
            height: '20px'
        },
        'span': {
            display: 'inline-block',
            marginLeft: '0.5rem',
            verticalAlign: 'middle'
        }
    }),

    itinerary__dumy__background: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        position: 'fixed',
        top: '0',
        zIndex: '-1'
    },

    itinerary__dumy__text: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 3,
        display: 'none',
        '&.map__off': mq({
            display: ['none', null, 'block']
        })
    },

    itinerary__page: {
        marginTop: '3rem'
    },
    itinerary__container: mq({
        border: '1px solid red',
        //maxHeight: ['760px'],
        //height: ['760px'],
    }),
    itinerary__menu__icon: mq({
        position: 'fixed',
        top: '2px',
        //left: '1rem',
        right: '3rem',
        zIndex: '999',
        padding: '0.5rem',
        borderRadius: '50%',
        borderColor: 'rgba(255, 255, 255, 0.08)',
        display: ['black', null, 'none'],
        'svg': {
            fontSize: '1.5rem',
            color: 'white',
            cursor: 'pointer'
        }
    }),
    itinerary__layout: mq({
        display: 'flex',
        flexDirection: 'row',
        marginTop: ['1px', null, '2rem'],
        flexWrap: 'wrap',
        position: 'relative',
        // 'div.gm-style' : {
        // }
    }),
    itinerary__side___close__panel: mq({
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'none',
        zIndex: 2,
        '&.open': mq({
            display: ['block', null, 'none'],
        })
    }),
    itinerary__side__panel: mq({
        flex: ['1 0 100%', null, '1 0 25%'],
        maxWidth: ['100%', null, '25%'],
        height: '100%',
        overflow: 'auto',
        position: ['absolute', null, 'static'],
        zIndex: 3,
        transition: 'all 0.3s ease-in-out',
        backgroundColor: 'white',
        width: '100%',
        // [mqMax.md]: {
        //     '&.open': {
        //         transform: 'translateX(0)'
        //     },
        //     '&.close': {
        //         transform: 'translateX(-100%)'
        //     }
        // },
        '&.atdw__popup__page__container': mq({
            //backgroundColor : '#fdf8e6',
            //overflow: 'hidden',
            '&.open': {
                transform: 'translateX(0)'
            },
            '&.close': {
                transform: 'translateX(-100%)'
            }
        })
    }),
    itinerary__main__panel: mq({
        flex: ['1 0 100%', null, '1 0 75%'],
        maxWidth: ['100%', null, '75%'],
        display: ['none', null, 'block'],
        '&.atdw__popup__open': mq({
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50%'],
        }),
        '.gm-ui-hover-effect': {
            //display: 'none !important'
        },
        '.info__window': {
            maxWidth: '300px',
            '.info__title': {
                fontSize: '16px',
                fontWeight: '600',
                marginBottom: '3px',
                color: variables.orangeColor,
                fontFamily: variables.familyHeader,
                textTransform: 'uppercase'
            }
        }

    }),
    itinerary__view__item__container: {
        padding: '0.5rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
    },
    itinerary__view__item__title: {
        fontWeight: 800,
        textAlign: 'center',
        color: variables.orangeColor
    },
    itinerary__view__item__des: {
        //fontWeight: 800
        marginTop: '1rem',
        fontSize: '0.7rem',
        'p': {
            marginBottom: '0.5rem'
        }
    },
    itinerary__day__item__des: {
        //fontWeight: 800
        marginTop: '1rem',
        fontSize: '0.7rem',
        'p': {
            marginBottom: '0.5rem'
        }
    },
    itinerary__hr__style: {
        borderTop: '0.5px solid #ebebeb'
    },

    itinerary__sub__items: {
        display: 'flex',
        '.itinerary__sub__item': {
            flex: '1 0 33.3%',
            maxWidth: '33.3%',
            paddingLeft: '0.2rem',
            paddingRight: '0.2rem',
            '&.selected': {
                pointerEvents: 'none',
                '.itinerary__sub__item__inner__inner': {
                    color: 'black'
                }
            }
        },
        '.itinerary__sub__item__inner': {
            background: '#f0582B',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            '.itinerary__sub__item__inner__inner': {
                lineHeight: '1.1',
                padding: '0.3rem 0.2rem',
                textAlign: 'center',
                color: 'white',
                fontWeight: '600',
                fontSize: '0.8rem'
            }
        }
    },

    itinerary__view__item__detail: {
        position: 'relative',
    },

    itinerary__day__container: {
        marginTop: '1rem'
    },

    itinerary__day__item__title: {
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        padding: '0.3rem 0.1rem',
        color: '#f0582B',
        fontWeight: 600,
        position: 'relative',
        cursor: 'pointer',
        '.itinerary__day__item__title__svg': {
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)'
        }
    },

    itinerary__item__view: {
        // display: 'flex',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        position: 'relative',
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
        paddingBottom: '0.5rem',
        '&::before': {
            position: 'absolute',
            content: "''",
            bottom: '5px',
            top: '12px',
            left: '12px',
            borderRight: '1px solid #f0582B'
        },
        '&::after': {
            position: 'absolute',
            content: "''",
            bottom: '0px',
            left: '8px',
            borderRight: '4.5px solid transparent',
            borderLeft: '4.5px solid transparent',
            borderTop: '9px solid #f0582B'
        },
        '&:last-child, &.one__day__trip': {
            '&::after': {
                position: 'absolute',
                content: "''",
                bottom: '0px',
                left: '8px',
                borderRight: '4.5px solid #f0582B',
                borderLeft: '4.5px solid #f0582B',
                borderTop: '9px solid #f0582B'
            }
        },
        '&.end__destination': {
            '&::after, &::before': {
                display: 'none'
            }
        }
    },
    itinerary__item__view__wrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '&:hover': {
            '&:not(.lo_dd)': {
                '.itinerary__item__view__detail__title': {
                    color: '#ea4335'
                }
            }

        },
        '&.lo_dd': {
            cursor: 'pointer',
            // '&:hover' : {
            //     '.itinerary__item__view__detail__title' : {
            //         color : '#ea4335'
            //     }
            // },
        }
    },

    itinerary__item__view__index: mq({
        // flex: '1 0 10%',
        // maxWidth: '10%',
        flexBasis: ['25px', '20px', null, '25px'],
        maxWidth : ['25px', '20px', null, '25px']
    }),
    itinerary__item__view__detail: {
        //flex: '1 0 90%',
        flex: '1 0 0%',
        paddingLeft : '10px',
        maxWidth: '90%',
        fontSize: '0.7rem',
        '.itinerary__item__view__detail__title': mq({
            color: variables.orangeColor,
            fontWeight: 700,
            marginTop: '0.3rem',
            marginBottom: '0.5rem',
            fontSize: ['18px', null, '20px'],
            paddingLeft: ['unset', 'unset', 'unset', null, null, 'unset'],
            '.itinerary__item__view__detail__inner__title': {
                //marginLeft : '0.5rem',
                //color : variables.fontColor
                //fontWeight : '400'
                display: 'inline-block'
            }
        })
    },
    itinerary__item__view__detail__small: {
        //flex: '1 0 90%',
        flex: '1 0 0%',
        maxWidth: '90%',
        fontSize: '0.7rem',
        paddingLeft: '10px',
        //marginBottom: '0.5rem',
        cursor: 'pointer',
        '.itinerary__item__view__detail__title': mq({
            color: variables.orangeColor,
            fontWeight: 700,
            marginTop: '0.3rem',
            marginBottom: '0.5rem',
            fontSize: ['15px', null, '17px'],
            // '&:hover' : {
            //     color : variables.r
            // }
        })
    },
    itinerary__item__view__detail__des: {
        'p': {
            marginBottom: '0.5rem'
        }
    },
    itinerary__view__index__item: mq({
        position: 'relative',
        height: ['25px', '20px', null, '25px'],
        width: ['25px', '20px', null, '25px'],
        backgroundColor: variables.orangeColor,
        borderRadius: '50%',
        marginTop: '4px',
        '.itinerary__view__index__item__inner': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            lineHeight: '1'
        }
    }),
    itinerary__view__index__item__small: {
        position: 'relative',
        // height: '9px',
        // width: '9px',
        // backgroundColor: variables.orangeColor,
        // borderRadius: '50%',
        //marginTop: '4px',
        '.itinerary__view__index__item__inner__small': {
            position: 'absolute',
            top: '12px',
            left: '8px',
            //color: 'white',
            //lineHeight: '1'
            height: '9px',
            width: '9px',
            backgroundColor: variables.orangeColor,
            borderRadius: '50%',
        }
    }
}