import { variables } from 'cssInJs'

export default{
    actions : {
        'button' : {
            backgroundColor: variables.orangeColor,
            '&:hover' : {
                backgroundColor: variables.dkOrangeColor,
                borderColor: variables.dkOrangeColor
            }
        }
    }
}