import { mq } from '../../../cssInJs';

export default {
    
    atdwTiles : {
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        //justifyContent: 'space-between'
        justifyContent: 'start',
        marginLeft: '-0.7rem',
        marginRight: '-0.7rem',
        '&.useSlider' : {
            display: 'block'
        }
    },

    atdwTiles__big : mq({
        display: 'flex',
         justifyContent: 'flex-start',
         flexWrap: 'wrap',
         margin: '2rem -10px',
         overflow:'hidden',
         //border:'1px solid red'
         marginLeft: ['-10px',null,null,null,'-2rem','-5.5rem'],
         marginRight: ['-10px',null,null,null,'-2rem','-5.5rem']
    })
    
}