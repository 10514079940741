/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, useRef, useCallback, Fragment } from 'react';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import env from '../../../../env';
import style from './atdwMapStyle';
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";

function Portal({children}){
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function AtdwMap(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdw-map', item.cssClass || item.anchorName || '');
    const fromType = props.fromType || "area";
    
    const atdwType = cmsUtils.payload(item, "AtdwType") || 'ALL';
    const mapArea = cmsUtils.payload(item, "MapArea") || (fromType === "town" ? "loxton" : 'all');

    const [mapState, setMapState] = useState({
        currentAtdwType : atdwType === "ALL" ? (fromType === 'town' ? 'ATTRACTION' : 'ACCOMM') : atdwType
    });
    
    const mapItem = useRef(null);
    const history = useHistory();
    const [openPopup, setOpenPopup] = useState(false);
    const [popupData, setPopupData] = useState();
    
    useEffect(() => {

        const abortController = new AbortController();
        const signal = abortController.signal;
        let checkScript = true;
        const script = document.createElement('script');

        const fetchData = async () => {

            //const res = await fetch(env.apiBase + "/api/atdw/getproducts/" + mapState.currentAtdwType, {signal:signal});
            const res = await fetch(env.apiBase + "/api/atdw/getmapproducts", {
                method : 'post',
                signal:signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    category : mapState.currentAtdwType,
                    mapArea : mapArea,
                    fromType : fromType
                })
            });

            const data = await res.json();
            
            if(window['google'] === undefined){
                checkScript = false;
            }
            else{
                if(window['google'].maps === undefined){
                    checkScript = true;
                }
            }

            if(!checkScript){
                script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
                script.async = true;
                document.body.appendChild(script);

                setTimeout(() => {
                    setupMap();
                }, 1000);
            }
            else{
                setupMap();
            }

            function setupMap(){
                const items = data.results.productResult.products;
                let center = {lat: -34.17547561936846, lng: 140.02757156230592};
                let zoom = 9;
                let mapPath = null;
                const mapLineOption = {geodesic: true, strokeColor: "#d44a33", strokeOpacity: 1.0, strokeWeight: 2};

                if(mapArea === 'east'){
                    center = {lat: -34.15645063232083, lng: 140.74006049706702};
                    zoom = 11;
                    mapPath = new window.google.maps.Polyline({
                        ...mapLineOption,
                        path : [
                            { lat: -34.10267722604226, lng: 140.57938170451808 },
                            { lat: -34.07274418546968, lng: 140.68324273122866 },
                            { lat: -33.97058173801264, lng: 140.64918400143875 },
                            { lat: -33.82325254108687, lng: 141.01950749703695 },
                            { lat: -34.43312277879625, lng: 141.01618241134287 },
                            { lat: -34.49879906366051, lng: 140.8966838554657 },
                            { lat: -34.10267722604226, lng: 140.57938170451808 }
                        ]
                    });
                }
                else if(mapArea === 'central'){
                    center = {lat: -34.27115834236997, lng: 140.60792884221695};
                    zoom = 10;
                    mapPath = new window.google.maps.Polyline({
                        ...mapLineOption,
                        path : [
                            { lat: -34.07622871072631, lng: 140.22212910088683 },
                            { lat: -34.12722076884324, lng: 140.46106837373443 },
                            { lat: -34.10267722604226, lng: 140.57938170451808 },
                            { lat: -34.49879906366051, lng: 140.8966838554657 },
                            { lat: -34.60822804198778, lng: 140.5911586974733 },
                            { lat: -34.35191069253517, lng: 140.22314098606688 },
                            { lat: -34.07622871072631, lng: 140.22212910088683 } 
                        ]
                    });
                }
                else if(mapArea === 'west'){
                    center = {lat: -34.15321294235024, lng: 139.82787743344488};
                    zoom = 10;
                    mapPath = new window.google.maps.Polyline({
                        ...mapLineOption,
                        path : [
                            { lat: -33.8548977222504, lng: 139.54142526792327 },
                            { lat: -34.07622871072631, lng: 140.22212910088683 },
                            { lat: -34.35191069253517, lng: 140.22314098606688 },
                            { lat: -34.25327954821813, lng: 140.06125313010946 },
                            { lat: -34.4950906230296, lng: 139.5480324822079 },
                            { lat: -33.8548977222504, lng: 139.54142526792327 }
                        ]
                    });
                }
                else if(fromType === "town" && mapArea === "loxton"){
                    center = {lat: -34.389577187333295, lng: 140.58616565490823};
                    zoom = 11;
                    mapPath = new window.google.maps.Polyline({
                        ...mapLineOption,
                        path : [
                            { lat: -34.42956929991063, lng: 140.66241694584906 },
                            { lat: -34.51573532920424, lng: 140.66221280479223 },
                            { lat: -34.515903535157165, lng: 140.60709475176404 },
                            { lat: -34.50766104412369, lng: 140.60321607395832 },
                            { lat: -34.50698815171927, lng: 140.487264021662 },
                            { lat: -34.459285140174174, lng: 140.51649253650788 },
                            { lat: -34.460028220241796, lng: 140.51936786466055 },
                            { lat: -34.45539270796296, lng: 140.5223719388499 },
                            { lat: -34.45606505012705, lng: 140.5267922194428 },
                            { lat: -34.45280944814033, lng: 140.52644889641616 },
                            { lat: -34.45178319949749, lng: 140.52945297060552 },
                            { lat: -34.45574657285607, lng: 140.53455989635398 },
                            { lat: -34.4527386727435, lng: 140.5467907694407 },
                            { lat: -34.44789041756394, lng: 140.54606120856613 },
                            { lat: -34.44651020586981, lng: 140.54902236740992 },
                            { lat: -34.447748858423886, lng: 140.55412929327227 },
                            { lat: -34.44835048300211, lng: 140.56189697055947 },
                            { lat: -34.443395798058454, lng: 140.5727974681125 },
                            { lat: -34.43829924418593, lng: 140.57129543098475 },
                            { lat: -34.43521992553833, lng: 140.5743424203476 },
                            { lat: -34.43461820635783, lng: 140.58095138356418 },
                            { lat: -34.43072462453833, lng: 140.58627288615568 },
                            { lat: -34.42732644131692, lng: 140.58820407670598 },
                            { lat: -34.425945889867364, lng: 140.62592666437084 },
                            { lat: -34.431007800182925, lng: 140.628458669759 },
                            { lat: -34.43029985913629, lng: 140.63910167545842 },
                            { lat: -34.42775122172015, lng: 140.64176242688328 },
                            { lat: -34.42973350198171, lng: 140.64781349060755 },
                            { lat: -34.42969810453212, lng: 140.66231887740756 }
                        ]
                    });
                }
                else if(fromType === "town" && mapArea === "waikerie"){
                    center = {lat: -34.178330889864164, lng: 139.9858583816066};
                    zoom = 12;
                    mapPath = new window.google.maps.Polyline({
                        ...mapLineOption,
                        // path : [
                        //     { lat: -33.8548977222504, lng: 139.54142526792327 },
                        //     { lat: -34.07622871072631, lng: 140.22212910088683 },
                        //     { lat: -34.35191069253517, lng: 140.22314098606688 },
                        //     { lat: -34.25327954821813, lng: 140.06125313010946 },
                        //     { lat: -34.4950906230296, lng: 139.5480324822079 },
                        //     { lat: -33.8548977222504, lng: 139.54142526792327 }
                        // ]
                    });
                }

                var map = new window.google.maps.Map(mapItem.current, {
                    center: center,
                    zoom: zoom,
                    mapId: "77731b8119077e37",
                });

                if(mapPath){
                    mapPath.setMap(map);
                }

                items.forEach(el => {
                    let geoCode = {
                        lat : Number(el.additionalData.googlemap.geocodeGdaLatitude),
                        lng : Number(el.additionalData.googlemap.geocodeGdaLongitude)
                    };
                    var marker = new window.google.maps.Marker({
                        position: {lat: geoCode.lat, lng: geoCode.lng},
                        map: map,
                        title:el.productName
                    });
                    var infowindow = new window.google.maps.InfoWindow({
                        //content : _el.shortDesc
                        content : '<div class="info__window">' +
                                    '<div class="info__title">' + el.productName + '</div>' + 
                                    '<div class="info__town">' + el.town + '</div>' + 
                                    '<div class="info__town">' + el.address + ' ' +  el.town + '</div>' + 
                                    '<p class="info__desc">' + el.shortDesc + '</p>' + 
                                '</div>'
                    });

                    var isMobile = false;
                    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                        isMobile = true;
                    }

                    marker.addListener('mouseover', function(){
                        if(!isMobile) {
                            infowindow.open(map, marker);
                        }
                    });
                    marker.addListener('mouseout', function(){ 
                        if(!isMobile) {
                            infowindow.close();
                        }
                    });
                    marker.addListener('click', function(){
                        if(isMobile) {
                            setPopupData({
                                productName : el.productName,
                                address : el.address,
                                town : el.town,
                                desc : el.shortDesc,
                                slug : el.slug
                            });
                            setOpenPopup(true);   
                        }
                        else{
                            history.push('/atdwproduct/' + el.slug);
                        }
                    });

                });
            }
        }

        fetchData();
        return function cleanup(){
            abortController.abort();
            if(!checkScript){
                document.body.removeChild(script);
            }
        }

        // eslint-disable-next-line
    }, [mapState.currentAtdwType]);

    const switchMap = useCallback((value)=>{
        
        setMapState({
            ...mapState,
            currentAtdwType : value
        });
        // setMapState(pre=>{
        //     let _new = {...pre};
        //     //_//new.fetchAble = true;
        //     _new.currentAtdwType = value;
        //     return _new;
        // });
        
        // eslint-disable-next-line
    }, []);

    const Filters = (() => {
        return(
            <div css={style.filters} className="atdw__map__filter">
                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'ACCOMM' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'ACCOMM'){
                        switchMap('ACCOMM');
                    }
                }}>ACCOMMODATION</button>
                
                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'ATTRACTION' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'ATTRACTION'){
                        switchMap('ATTRACTION');
                    }
                }}>ATTRACTIONS</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'RESTAURANT' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'RESTAURANT'){
                        switchMap('RESTAURANT');
                    }
                }}>EAT & DRINK</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'EVENT' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'EVENT'){
                        switchMap('EVENT');
                    }
                }}>EVENTS</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'HIRE' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'HIRE'){
                        switchMap('HIRE');
                    }
                }}>HIRE</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'INFO' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'INFO'){
                        switchMap('INFO');
                    }
                }}>INFO</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'TOUR' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'TOUR'){
                        switchMap('TOUR');
                    }
                }}>TOURS</button>

                <button css={style.button} className={`btn btn-primary map-button ${mapState.currentAtdwType === 'GENSERVICE' ? 'selected' : ''}`} onClick={()=>{
                    if(mapState.currentAtdwType !== 'GENSERVICE'){
                        switchMap('GENSERVICE');
                    }
                }}>GENERAL</button>
            </div>
        )
    });

    return(
        <Fragment>
            <Portal>
                {
                    openPopup && 
                    <Fragment>
                        <div css={style.popupBackground}></div>
                        <div css={style.container}>
                            <div css={style.popupTitle}> { popupData.productName } </div>
                            <div css={style.popupTown}> { popupData.town } </div>
                            <div css={style.popupTown}> { popupData.address + ' ' +  popupData.town } </div>
                            <p css={style.popupDesc}> { popupData.desc } </p>
                            <div style={{textAlign:'center'}}>
                                    <button className="btn btn-primary" onClick={()=>{
                                        history.push('/atdwproduct/' + popupData.slug);
                                    }}>Go</button>
                                    <button className="btn btn-primary" style={{marginLeft:'1rem'}} onClick={()=>{
                                        setOpenPopup(false);
                                        setPopupData(null);
                                    }} >Close</button>
                            </div>
                        </div>
                    </Fragment>
                }
            </Portal>
            <div css={style.atdwMap} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}> 
                <div style = {{height : "450px"}}>
                    <div ref = {mapItem} style = {{height:"100%"}}></div>
                </div>
                {
                    atdwType === 'ALL' &&
                    <Filters></Filters>
                }
            </div>
        </Fragment>
    )
}