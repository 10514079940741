/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import style from './atdwPageTilesStyle';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import { Loading } from 'components';
import env from '../../../../env';
import { AtdwSearchResult } from '../../../atdw/atdwSearchResult/atdwSearchResult';

export function AtdwPageTiles(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdw__page__tiles', item.cssClass || item.anchorName || '');
    const [searchPedding, setSearchPedding] = useState(true);
    const [atdwDataInfo, setAtdwDataInfo] = useState();
    const selectedPages = cmsUtils.payload(item, "SelectedPages") || '';
    const tileType = cmsUtils.payload(item, 'TileType') || '2';
    const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';

    //const selectedPages = '';

    useEffect(() => {

        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/atdw/getproductsatdwtiles", {
                method: 'post',
                signal: signal,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    selectedPages: selectedPages
                })
            });

            const data = await res.json();

            if (data && data.results && data.results.productResult) {
                setAtdwDataInfo({
                    atdwData: data.results.productResult.products
                });
            }
            setSearchPedding(false);
        };

        fetchData();
        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    return (
        <div css={style.atdwMap} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                searchPedding ? <Loading></Loading> :  <AtdwSearchResult atdwData={atdwDataInfo.atdwData} tileType = {tileType} useSlider={useSlider} />
            }
            {
                atdwDataInfo && atdwDataInfo.atdwData && atdwDataInfo.atdwData.length < 1 &&
                <div css={style.noResultText}> Sorry, there are no available results. </div>
            }
        </div>
    )
}