/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './atdwTileBigStyle';
import { SiteLink } from '../../../components';
import utils from '../../../utils';
import Slider from "react-slick";

export function AtdwTileBig(props) {
    const product = props;
    // let firstDate = "";
    // if ((product.additionalData && product.additionalData.eventFrequency && product.additionalData.eventFrequency.length > 0) && product.productType === 'EVENT') {
    //     const dateArray = (product.additionalData.eventFrequency[0].frequencyStartDate).split("-");
    //     firstDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
    // }

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        autoplaySpeed: 3000,
        fade: true,
        autoplay: true,
        //autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    
    const sliderImgs = [(product.additionalData && product.additionalData.bigSizeBgImg) ? product.additionalData.bigSizeBgImg.serverPath :  product.imageUrl];
    const index = props.index;
    
    const slides = sliderImgs.map((item, index) =>
        <div key={index}>
            <div className="pageTile__bsyc__bg2__inner" css={style.tile__bg2__inner} style={{ backgroundImage: utils.css.bgUrlStyle(item) }}></div>
        </div>
    );

    return (
        <div css={style.pageTile__tile} className="pageTile__bsyc">
            <SiteLink className="pageTile__bsyc__content" css={style.tile__content} to={'/atdwproduct/' + product.slug}>
                <div css={style.tile__flip__wrapper}>


                    <div className="pageTile__bsyc__bg" data-aos={index % 2 === 0 ? 'fade-up-right' : 'fade-up-left'} css={style.tile__bg}>
                        {/* <div className="pageTile__bsyc__bg2" css = {style.tile__bg2} style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div> */}
                        <div className={`pageTile__bsyc__bg2 ${(index % 2 === 0) ? 'even' : 'odd'}`} css={style.tile__bg2}>
                            <Slider {...settings} >
                                {slides}
                            </Slider>
                        </div>
                    </div>

                    <div css={style.tile__details} className={`pageTile__bsyc__content__details orange ${(index % 2 === 0) ? 'even' : 'odd'}`} data-aos={index % 2 === 0 ? 'fade-up-left' : 'fade-up-right'}>
                        <div className="pageTile__bsyc__container">

                            {
                                (product.productName && product.productName !== '') &&
                                <div className="pageTile__bsyc__title">
                                    {product.productName}
                                </div>
                            }

                            <hr className="pageTile__bsyc__dot" />

                            <div className="pageTile__bsyc__desc">
                                {product.shortDesc || product.shortDesc}
                            </div>
                        </div>
                    </div>

                </div>
            </SiteLink>
        </div>
    )
}