import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import BottomDrawer from '../../components/bottomDrawer'
import { FaCaretUp } from "react-icons/fa";

export function BottomDock(props) {
  const links = [
    { url: '/things-to-do', title: 'THINGS TO DO', icon: 'our-river-WHITE.png', iconHover: 'our-river-WHITE.png' },
    { url: '/eat-and-drink', title: 'EAT & DRINK', icon: 'eat-and-drink-WHITE.png', iconHover: 'eat-and-drink-WHITE.png' },
    { url: '/accommodation', title: 'ACCOMMODATION', icon: 'accommodation-WHITE.png', iconHover: 'accommodation-WHITE.png' },
    { url: '/explore', title: 'EXPLORE', icon: 'things-to-do-WHITE.png', iconHover: 'things-to-do-WHITE.png' },
    { url: '/events', title: 'EVENTS ', icon: 'events-WHITE.png', iconHover: 'events-WHITE.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  const bottomMenu = useRef(null);
  
  useLayoutEffect(() => {
    const slider = new BottomDrawer(bottomMenu.current);

    setTimeout(() => {
      slider.init(pathname);
    }, 1000);
    
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <nav className="bottomMenu" ref={bottomMenu}>
        <div className="bottomMenu__bar">
          <div className="bottomMenu__line1" style={{display:'none'}}></div>
          <div className="bottomMenu__line2" style={{display:'none'}}></div>
          <div className="bottomMenu__text"> 
            <div style={{position:'relative'}}>
                EXPLORE THE RIVERLAND
              <FaCaretUp />
            </div>
          </div>
        </div>
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      <div className="bottomMenuPadding"></div>
    </>
  );
}
