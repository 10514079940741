/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './atdwSearchResultStyle';
import { AtdwTile } from '../atdwTile/atdwTile';
import { AtdwTileBig } from '../atdwTile/atdwTileBig';
import { Fragment } from 'react';
import Slider from "react-slick";

export function AtdwSearchResult({ atdwData, tileType = '2', useSlider }) {
    const _atdwData = atdwData || [];


    console.log("_atdwData", _atdwData);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        fade: false,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <div className="atdwResult">
            {
                tileType === '2' ?
                    <Fragment>
                        {
                            useSlider ?
                                <div css={style.atdwTiles} className={`atdwTiles useSlider`}>
                                    <Slider {...settings} >
                                        {
                                            _atdwData.map((item, index) => <AtdwTile {...item} key={index} useSlider = {useSlider} />)
                                        }
                                    </Slider>
                                </div> :
                                <div css={style.atdwTiles} className={`atdwTiles`}>
                                    {
                                        _atdwData.map((item, index) => <AtdwTile {...item} key={index} />)
                                    }
                                </div>
                        }
                    </Fragment> :
                    <div css={style.atdwTiles__big} className="atdwTiles__big">
                        {
                            _atdwData.map((item, index) => <AtdwTileBig {...item} key={index} index={index} />)
                        }
                    </div>
            }
        </div>
    )
}