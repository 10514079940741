/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './itineraryStyle';
import { Fragment, useEffect, useState, useRef } from 'react';
import { useRouter, Loading, NotFound } from '../../../components';
import env from '../../../env';
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { MdArrowDropDownCircle } from "react-icons/md";
import { isMobile } from '../../siteUtils';
import { ViewPartial } from '../../../cms/pages/partials/viewPartial';
import { AtdwPopupPage } from './atdwPopupPage/atdwPopupPage';

export function Itinerary() {

    const slug = useRouter().query.slug;
    const [itineraryData, setItineraryData] = useState();
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/itinerary/getitinerary/" + slug, { signal: signal });
            const data = await res.json();
            console.log("data ---->", data);

            if (data.statusCode && data.statusCode === 500) {
                setNotFound(true);
            }
            else {
                setItineraryData(data);
            }
        };

        fetchData();

        return function cleanup() {
            abortController.abort();
        }

        // eslint-disable-next-line
    }, []);

    if (!itineraryData && notFound === false) {
        return <Loading />
    }
    else if (notFound) {
        return <NotFound />
    }
    return (
        <Fragment>
            <ItineraryLayout itineraryData={itineraryData} />
            <div style={{ display: 'none' }}>
                <ViewPartial />
            </div>
        </Fragment>
    )
}

function ItineraryLayout({ itineraryData }) {

    const mapItem = useRef(null);
    const layoutRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [checkCurrentDayItemOpen, setCheckCurrentDayItemOpen] = useState(false);

    const [markersData, setMarkersData] = useState();
    const [markersDataD, setMarkersDataD] = useState();
    const [mapData, setMapData] = useState();

    const [currentSubItem, setCurrentSubItem] = useState(itineraryData.subItems[0]);
    const [currentDayItem, setCurrentDayItem] = useState();
    const [directionsInfo, setDirectionsInfo] = useState();
    const [atdwPopupTrigger, setAtdwPopupTrigger] = useState(null);
    const [currentMapPinId, setCurrentMapPinId] = useState(null);

    function openMarkerItem(item) {
        setMapOnStartItem(markersData);
        for (let i in markersData) {
            if (markersData[i].id === item.detailItemId) {
                markersData[i].setMap(mapData);
                window.google.maps.event.trigger(markersData[i], 'mouseover');
                break;
            }
        }
    }

    function closeMarkerItem(item) {
        setMapOnStartItem(markersData);
        if (currentMapPinId !== null) {
            for (let i in markersData) {
                if (markersData[i].id === currentMapPinId) {
                    markersData[i].setMap(mapData);
                    window.google.maps.event.trigger(markersData[i], 'mouseover');
                    break;
                }
            }
        }
    }

    function setMapOnStartItem(markers) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
    }

    function openMarkerItemD(index) {
        for (let i in markersDataD) {
            //window.google.maps.event.trigger(markersDataD[i], 'mouseout');
            if (markersDataD[i].id === index + 1) {
                window.google.maps.event.trigger(markersDataD[i], 'mouseover');
                break;
            }
        }
    }

    function closeMarkerItemD(index) {
        for (let i in markersDataD) {
            if (markersDataD[i].id === index + 1) {
                window.google.maps.event.trigger(markersDataD[i], 'mouseout');
                break;
            }
        }
    }

    function updateCurrentSubItem(subItem) {
        setAtdwPopupTrigger(null);
        setCurrentSubItem(subItem);
    }

    function updateCurrentDayItem(dayItem) {
        
        console.log("dayItem.dayItemId", dayItem.dayItemId);
        
        if (dayItem === null) {
            setCheckCurrentDayItemOpen(false);
        }
        else {
            if (currentDayItem && dayItem.dayItemId === currentDayItem.dayItemId && checkCurrentDayItemOpen) {
                setCheckCurrentDayItemOpen(false);
            }
            else {
                setCheckCurrentDayItemOpen(true);
            }

            if (currentDayItem && currentDayItem.dayItemId !== dayItem.dayItemId) {
                setAtdwPopupTrigger(null);
            }
        }

        setCurrentDayItem(dayItem);
        // if (isMobile()) {
        //     setMenuOpen(true);
        // }
        if (!isMobile()) {
            //setMenuOpen(false);
        }
    }

    function setupAtdwPopupData(atdwData) {
        if (!isMobile()) {
            if (atdwPopupTrigger === atdwData.atdwProductId) {
                setAtdwPopupTrigger(null);
                setCurrentMapPinId(null);
            }
            else {
                setAtdwPopupTrigger(atdwData.atdwProductId);
                setCurrentMapPinId(atdwData.detailItemId);
                setMapOnStartItem(markersData);
                for (let i in markersData) {
                    if (markersData[i].id === atdwData.detailItemId) {
                        markersData[i].setMap(mapData);
                        window.google.maps.event.trigger(markersData[i], 'mouseover');
                        break;
                    }
                }
            }
        }
        else {
            setAtdwPopupTrigger(atdwData.atdwProductId);
            setMenuOpen(true);
        }
    }

    function closeSetMenuOpen() {
        setMenuOpen(false);
    }

    useEffect(() => {

        let checkScript = true;
        const script = document.createElement('script');

        if (window['google'] === undefined) {
            checkScript = false;
        }
        else {
            if (window['google'].maps === undefined) {
                checkScript = true;
            }
        }

        if (!checkScript) {
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY';
            script.async = true;
            document.body.appendChild(script);
            setTimeout(() => {
                setupMap();
            }, 500);
        }
        else {
            setupMap();
        }

        function setupMap() {

            if (!isMobile()) {
                let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
                let zoom = 9;
                let map = new window.google.maps.Map(mapItem.current, {
                    center: center,
                    zoom: zoom,
                    mapId: "2328f65e96956828",
                });

                let directionsService = new window.google.maps.DirectionsService();
                //let directionsRenderer = new window.google.maps.DirectionsRenderer();
                let directionsRenderer = new window.google.maps.DirectionsRenderer({
                    polylineOptions: {
                        strokeColor: "#e27443",
                        strokeOpacity: 0.7,
                        strokeWeight: 6,
                    },
                    suppressMarkers: true
                });

                directionsRenderer.setMap(map);

                setDirectionsInfo({
                    directionsService: directionsService,
                    directionsRenderer: directionsRenderer
                });
                setMapData(map);
            }
            else {
                setMapData({
                    type: 'mobile'
                });
            }
        }

        const resized = (() => {
            const offset = window.innerWidth >= 992 ? 220 : 100;
            layoutRef.current.style.height = (window.innerHeight - offset) + "px";
        });
        resized();
        window.addEventListener('resize', resized);

        return function cleanup() {

            if (!checkScript) {
                document.body.removeChild(script);
            }

            if (typeof window !== `undefined`) {
                window.removeEventListener('resize', resized)
            }
        }

        // eslint-disable-next-line
    }, [currentSubItem]);

    useEffect(() => {

        if (!currentDayItem || currentDayItem === null) {
            return
        }

        if (markersDataD && markersDataD.length > 0) {
            for (let i = 0; i < markersDataD.length; i++) {
                markersDataD[i].setMap(null);
            }
        }

        if (markersData && markersData.length > 0) {
            setMapOnStartItem(markersData);
        }

        const locationItems = currentDayItem.locationItems;
        let markers = [];

        if (!isMobile()) {
            locationItems.forEach(ele => {
                ele.detailItems.forEach(el => {
                    let geoCode = {
                        lat: Number(el.lat),
                        lng: Number(el.lng)
                    };
                    let marker = new window.google.maps.Marker({
                        position: { lat: geoCode.lat, lng: geoCode.lng },
                        map: mapData,
                        title: el.title,
                        id: el.detailItemId,
                        zIndex: 100
                    });
                    let infowindow = new window.google.maps.InfoWindow({
                        content:
                            '<div class="info__window">' +
                            '<div class="info__title">' + el.detailItemTitle + '</div>' +
                            '<div class="info__img">' +
                            '<img src="' + el.thumbnail + '" alt="img" width="100%" >' +
                            '</div>' +
                            '</div>'
                    });

                    infowindow.addListener('closeclick', function () {
                        setMapOnStartItem(markers);
                    });

                    marker.addListener('mouseover', function () {
                        infowindow.open(mapData, marker);
                    });
                    marker.addListener('mouseout', function () {
                        infowindow.close();
                    });

                    markers.push(marker);
                });
            });

            setMapOnStartItem(markers);

            if (locationItems && locationItems.length > 0) {
                setTimeout(() => {
                    calculateAndDisplayRoute(directionsInfo.directionsService, directionsInfo.directionsRenderer, locationItems, mapData);
                }, 100);
            }

            setMarkersData(markers);
        }

        function calculateAndDisplayRoute(directionsService, directionsRenderer, locationItems, map) {

            console.log("locationItems", locationItems);

            let markers = [];

            const origin = locationItems[0];
            const destination = locationItems[0].endDestination && locationItems[0].endDestination !== '' ? { location: locationItems[0].endDestination } : locationItems[locationItems.length - 1];

            const _waypts = locationItems.slice(1, -1);
            const waypts = [];

            _waypts.forEach(el => {
                waypts.push({
                    location: el.location,
                    stopover: true,
                    //abc : "sfsfsdfsdf",
                });
            });

            directionsService.route({
                origin: origin.location,
                destination: destination.location,

                //origin: 'Loxton SA',
                //destination: 'Loxton SA',

                optimizeWaypoints: false,
            
                waypoints: waypts,
                travelMode: 'DRIVING',
            }).then((response) => {
                directionsRenderer.setDirections(response);
                
                // setTimeout(() => {
                //     map.setZoom(9);
                // }, 1000);

                let mapCount = 1;
                let lastItme;

                for (let i = 0; i < response.routes[0].legs.length; i++) {
                    const leg = response.routes[0].legs[i];

                    //console.log("leg.start_location", leg.start_location);

                    let _mapCount = mapCount;
                    //let _itemIndex = 
                    let marker = new window.google.maps.Marker({
                        position: leg.start_location,
                        map: map,
                        //icon: "/assets/pin/pin-" + (_mapCount > 3 ? 1 : _mapCount) + ".svg?v=1",
                        icon: "/assets/pin/" + _mapCount +".svg?v=3",
                        title: mapCount,
                        id: _mapCount,
                    });

                    marker.addListener('mouseover', function () {
                        //marker.setIcon("/assets/pin/pin-hover-" + (_mapCount > 3 ? 1 : _mapCount) + ".svg?v=1");
                        marker.setIcon("/assets/pin/" + _mapCount + ".svg?v=3");
                    });

                    marker.addListener('mouseout', function () {
                        //marker.setIcon("/assets/pin/pin-" + (_mapCount > 3 ? 1 : _mapCount) + ".svg?v=1");
                        marker.setIcon("/assets/pin/" + _mapCount + ".svg?v=3");
                    });

                    lastItme = leg;
                    markers.push(marker);
                    mapCount++;
                }

                let marker1 = new window.google.maps.Marker({
                    position: lastItme.end_location,
                    map: map,
                    icon: "/assets/pin/" + mapCount + ".svg?v=3",
                    title: mapCount,
                    id: mapCount
                });

                marker1.addListener('mouseover', function () {
                    marker1.setIcon("/assets/pin/" + mapCount + ".svg?v=3");
                });

                marker1.addListener('mouseout', function () {
                    marker1.setIcon("/assets/pin/" + mapCount + ".svg?v=3");
                });

                markers.push(marker1);

                setMarkersDataD(markers);
            }).catch((e) => window.alert("Directions request failed due to"));
        }

        // eslint-disable-next-line
    }, [currentDayItem]);

    return (
        <Fragment>

            {/* <div className='itinerary__menu__icon' css={style.itinerary__menu__icon}> <AiOutlineMenuUnfold onClick={() => {
                //setMenuOpen(!menuOpen);
                closeSetMenuOpen();
            }} /></div> */}

            {/* <div style={{position : 'fixed', top : '7%', zIndex: 9}}>
                TEST
            </div> */}

            {
                menuOpen &&
                <div className='atdw__popup__page__back__to__menu' css={style.atdw__popup__page__back__to__menu} onClick={closeSetMenuOpen}>
                    <AiOutlineMenuUnfold />
                    <span>Back to menu</span>
                </div>
            }

            <div ref={layoutRef} className={`itinerary__layout ${menuOpen ? "open" : "close"}`} css={style.itinerary__layout}>
                <div className={`itinerary__side__panel ${menuOpen ? "open" : "close"}`} css={style.itinerary__side__panel}>
                    <ItineraryView
                        itineraryData={itineraryData}
                        openMarkerItem={openMarkerItem}
                        closeMarkerItem={closeMarkerItem}
                        openMarkerItemD={openMarkerItemD}
                        closeMarkerItemD={closeMarkerItemD}
                        currentSubItem={currentSubItem}
                        setCurrentSubItem={setCurrentSubItem}
                        currentDayItem={currentDayItem}
                        setCurrentDayItem={setCurrentDayItem}
                        updateCurrentSubItem={updateCurrentSubItem}
                        updateCurrentDayItem={updateCurrentDayItem}
                        checkCurrentDayItemOpen={checkCurrentDayItemOpen}
                        setupAtdwPopupData={setupAtdwPopupData} />
                </div>

                {
                    !isMobile() && atdwPopupTrigger && atdwPopupTrigger !== '' &&
                    <div className={`itinerary__side__panel ${menuOpen ? "open" : "close"}`} css={style.itinerary__side__panel}>
                        <AtdwPopupPage atdwPopupId={atdwPopupTrigger} />
                    </div>
                }
                {
                    isMobile() &&
                    <div className={`itinerary__side__panel atdw__popup__page__container ${menuOpen ? "open" : "close"}`} css={style.itinerary__side__panel}>
                        {
                            atdwPopupTrigger && atdwPopupTrigger !== '' &&
                            <AtdwPopupPage atdwPopupId={atdwPopupTrigger} closeSetMenuOpen={closeSetMenuOpen} />
                        }
                    </div>
                }

                {/* <div className={`itinerary__side___close__panel ${menuOpen ? "open" : "close"}`} css={style.itinerary__side___close__panel} onClick={() => {
                    setMenuOpen(false);
                }}></div> */}
                <div className={`itinerary__main__panel ${(atdwPopupTrigger && atdwPopupTrigger !== '') ? 'atdw__popup__open' : 'atdw__popup__open__close'}`} css={style.itinerary__main__panel}>
                    <div ref={mapItem} style={{ height: "100%" }}></div>
                </div>
            </div>
            <div className='itinerary__dumy__background' css={style.itinerary__dumy__background}></div>
            {
                <div className={`itinerary__dumy__text ${(mapData && mapData.type && mapData.type === 'mobile') ? 'map__off' : 'map__on'}`} css={style.itinerary__dumy__text}>
                    <button className="btn btn-primary" type="button" onClick={() => {
                        window.location.reload();
                    }}> Please refresh page </button>
                </div>
            }

        </Fragment>
    )
}

function ItineraryView({
    itineraryData,
    openMarkerItem,
    closeMarkerItem,
    currentSubItem,
    setupAtdwPopupData,
    currentDayItem, updateCurrentSubItem, updateCurrentDayItem, checkCurrentDayItemOpen, openMarkerItemD, closeMarkerItemD }) {

    const title = itineraryData.mainItemTitle;
    const des = itineraryData.mainItemDes;
    const subItems = itineraryData.subItems

    return (
        <div id='itinerary__view__item__container' className='itinerary__view__item__container' css={style.itinerary__view__item__container}>
            <div className='itinerary__view__item__title' css={style.itinerary__view__item__title}>
                {title}
            </div>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <img src="/assets/logos/riverland-logo-trip-planner-padded.png" width='100%' alt='img' style={{ maxWidth: '400px', margin: 'auto', display: 'block' }} />
            </div>
            <div className='itinerary__view__item__des' css={style.itinerary__view__item__des} dangerouslySetInnerHTML={{ __html: des }}></div>
            <hr className='hr__style' css={style.itinerary__hr__style} />

            <div className='itinerary__sub__items' css={style.itinerary__sub__items}>
                {
                    subItems.map((item, index) => <div className={`itinerary__sub__item ${currentSubItem.subItemId === item.subItemId ? 'selected' : 'unselected'}`} key={index}>
                        <div className={`itinerary__sub__item__inner`} onClick={() => {
                            updateCurrentSubItem(subItems[index]);
                            updateCurrentDayItem(null);
                        }}>
                            <div className='itinerary__sub__item__inner__inner'>
                                {item.subItemTitle}
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <DayItems
                dayItems={currentSubItem.dayItems}
                updateCurrentDayItem={updateCurrentDayItem}
                currentDayItem={currentDayItem}
                openMarkerItem={openMarkerItem}
                closeMarkerItem={closeMarkerItem}
                checkCurrentDayItemOpen={checkCurrentDayItemOpen}
                openMarkerItemD={openMarkerItemD}
                closeMarkerItemD={closeMarkerItemD}
                setupAtdwPopupData={setupAtdwPopupData} />
        </div>
    )
}

function DayItems({ dayItems, updateCurrentDayItem, currentDayItem, openMarkerItem, closeMarkerItem, openMarkerItemD, checkCurrentDayItemOpen, closeMarkerItemD, setupAtdwPopupData }) {
    return (
        <div className='itinerary__day__container' css={style.itinerary__day__container}>
            {
                dayItems && dayItems.length > 0 ?
                    <div>
                        {
                            dayItems.map((item, index) =>
                                <div key={index}>
                                    <div className='itinerary__day__item__title' css={style.itinerary__day__item__title} onClick={() => {
                                        updateCurrentDayItem(item);
                                    }}>
                                        {(item.dayItemCustomTitle && item.dayItemCustomTitle !== '') ? item.dayItemCustomTitle : item.dayItemTitle.replace(" Days","").replace(" Day","") + " Stop"}
                                        <div className='itinerary__day__item__title__svg'> <MdArrowDropDownCircle /> </div>
                                    </div>
                                    {
                                        currentDayItem && currentDayItem.dayItemId === item.dayItemId && checkCurrentDayItemOpen &&
                                        <Fragment>
                                            <ItineraryDes dayItemDes={item.dayItemDes} />
                                            <LocationItems
                                                locationItems={item.locationItems}
                                                openMarkerItem={openMarkerItem}
                                                closeMarkerItem={closeMarkerItem}
                                                openMarkerItemD={openMarkerItemD}
                                                closeMarkerItemD={closeMarkerItemD}
                                                setupAtdwPopupData={setupAtdwPopupData}
                                                dayItemTitle={item.dayItemTitle} />
                                        </Fragment>
                                    }
                                </div>)
                        }
                    </div> : <div> No Iitems </div>
            }
        </div>
    )
}

function ItineraryDes({ dayItemDes }) {
    return (
        <div className='itinerary__day__item__des' css={style.itinerary__day__item__des} dangerouslySetInnerHTML={{ __html: dayItemDes }}></div>
    )
}

function LocationItems({ locationItems, openMarkerItem, closeMarkerItem, openMarkerItemD, closeMarkerItemD, setupAtdwPopupData, dayItemTitle }) {

    //console.log("dayItemTitle", dayItemTitle, locationItems);

    function onEnterDest(index) {
        openMarkerItemD(index);
    }

    function onLeaveDest(index) {
        closeMarkerItemD(index)
    }

    return (
        <div>
            {
                locationItems.map((item, index) =>
                    <div key={index}>

                        <div className={`itinerary__item__view ${locationItems.length === 1 ? 'one__day__trip' : 'none__one__day__trip'}`} css={style.itinerary__item__view}>
                            <div className='itinerary__item__view__wrapper lo_dd' css={style.itinerary__item__view__wrapper} onMouseEnter={() => { onEnterDest(index) }} onMouseLeave={() => { onLeaveDest(index) }}>
                                <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                                    <div className='itinerary__view__index__item' css={style.itinerary__view__index__item}>
                                        <div className='itinerary__view__index__item__inner'>
                                            {
                                                locationItems.length === 1 ? '1' : item.seq
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='itinerary__item__view__detail' css={style.itinerary__item__view__detail}>
                                    <div className='itinerary__item__view__detail__title'>
                                        <span className='itinerary__item__view__detail__inner__title'>
                                            {
                                                item.destinationTitle && item.destinationTitle !== '' && item.destinationTitle
                                            }
                                        </span>
                                    </div>
                                    <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des} dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                </div>
                            </div>
                            {
                                item.detailItems.map((itemN, index) => <ItineraryItemDetailView key={index} index={index} item={itemN} openMarkerItem={openMarkerItem} closeMarkerItem={closeMarkerItem} openMarkerItemD={openMarkerItemD} setupAtdwPopupData={setupAtdwPopupData} />)
                            }

                        </div>
                        {
                            (dayItemTitle === '1 Day' || dayItemTitle === '1') && locationItems.length === 1 && item.endDestination && item.endDestination !== '' &&
                            <div className='itinerary__item__view end__destination' css={style.itinerary__item__view}>
                                <div className='itinerary__item__view__wrapper lo_dd' css={style.itinerary__item__view__wrapper} onMouseEnter={() => { onEnterDest(index + 1) }} onMouseLeave={() => { onLeaveDest(index + 1) }}>
                                    <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                                        <div className='itinerary__view__index__item' css={style.itinerary__view__index__item}>
                                            <div className='itinerary__view__index__item__inner'>2</div>
                                        </div>
                                    </div>
                                    <div className='itinerary__item__view__detail' css={style.itinerary__item__view__detail}>
                                        <div className='itinerary__item__view__detail__title'> End </div>
                                        <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des} dangerouslySetInnerHTML={{ __html: item.endDescription }}></div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                )
            }
        </div>
    )
}

function ItineraryItemDetailView({ item, openMarkerItem, closeMarkerItem, setupAtdwPopupData }) {

    function onEnter() {
        openMarkerItem(item);
    }

    function onLeave() {
        closeMarkerItem(item);
    }

    function onAtdwPopupData() {
        setupAtdwPopupData(item);
    }

    //isMobile
    return (
        <div className='itinerary__item__view__wrapper' css={style.itinerary__item__view__wrapper}>
            <div className='itinerary__item__view__index' css={style.itinerary__item__view__index}>
                <div className='itinerary__view__index__item__small' css={style.itinerary__view__index__item__small}>
                    <div className='itinerary__view__index__item__inner__small'></div>
                </div>
            </div>
            {
                isMobile() ?
                    <div className='itinerary__item__view__detail__small' css={style.itinerary__item__view__detail__small} onClick={onAtdwPopupData}>
                        <div className='itinerary__item__view__detail__title'> {item.detailItemTitle} </div>
                        {/* <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div> */}
                    </div> :
                    <div className='itinerary__item__view__detail__small' css={style.itinerary__item__view__detail__small}
                        onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={onAtdwPopupData}>
                        <div className='itinerary__item__view__detail__title'> {item.detailItemTitle} </div>
                        {/* <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div> */}
                    </div>
            }
        </div>
    )
}