import { css } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [60, null, 130],
    background: variables.orangeColor,
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s',

    '&.atdw-page': css(mq({
      height: [44, null, 75],
      //zIndex: '0',
      'a.site-link': css(mq({
        img: css(mq({
          height: [22, null, 60],
          marginTop: ['0px', null, '0.5rem']
        }))
      }))
    })),

    '&.itinerary__page__top': mq({
      height: [44, null, 75],
      '.site-link img': mq({
        height: [34, null, 60],
        marginTop: ['0px', null, '0.5rem']
      }),
      // '.wave-animation-bottom.in-header':{
      //   display:'none'
      // }
    }),

  })),
  topScrolled: css(mq({
    height: [44, null, 75],
  })),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [42, null, 100],
    transition: 'all .2s'
  })),
  logoScrolled: css(mq({
    img: css(mq({
      height: [34, null, 60],
      marginTop: ['0px', null, '0.5rem']
    }))
  })),
}